import localStorage from '../localStorage';
import encryption from '@sstdev/lib_encryption';
import lodash from 'lodash';
const { isEqual } = lodash;
import simpleChangeObserver from '../simpleChangeObserver';

const { onChange, publishChange } = simpleChangeObserver();
const _p = {
    localStorage
};
export const _private = _p;
export const onCacheChange = onChange;
export async function saveProfile(profile) {
    let profiles = (await _p.localStorage.getKey('profiles', '', undefined, false)) || {};
    // merged new profile with stored profile or just use the new profile if no stored profile
    let storedUser = profiles[profile.userId ?? profile._id] || {};
    storedUser = { ...storedUser, ...profile };
    profiles[profile.userId ?? profile._id] = storedUser;
    await _p.localStorage.setKey('profiles', profiles, '', false);
    publishChange('profile', profile);
}
export async function setActiveProfileId(profileId) {
    await _p.localStorage.setKey('activeProfileId', profileId, '', false);
}
export async function clearActiveProfileId() {
    await _p.localStorage.deleteKey('activeProfileId', '', false);
}

export async function getProfile(throwIfMissing = true) {
    const activeProfileId = await _p.localStorage.getKey('activeProfileId', '', undefined, false);
    if (!activeProfileId) {
        if (throwIfMissing) {
            throw new Error('No active profile set');
        }
        return;
    }
    const profiles = await _p.localStorage.getKey('profiles', '', undefined, false);
    if (!profiles) {
        if (throwIfMissing) {
            throw new Error('No profiles found');
        }
        return;
    }

    const activeProfile = profiles[activeProfileId];
    return activeProfile;
}

/**
 * Retrieves the active use case and tenant for the given user.
 *
 * @param {Object} profile - The user object.
 * @returns {Promise<{tenant:object, useCase:object}>} - A promise that resolves to the active use case and tenant object.
 */
export async function getActiveUseCaseAndTenant(profile) {
    let activeUseCaseAndTenant = await _p.localStorage.getKey('activeUseCaseAndTenant', '', {}, false);
    // If there's only one option for this user, then make sure that's what we have stored
    // and then return it.
    if (profile?.tenant?.length === 1 && profile?.tenant?.[0].useCase.length === 1) {
        const onlyUseCaseAndTenant = { useCase: profile.tenant[0].useCase[0], tenant: profile.tenant[0] };
        if (!isEqual(activeUseCaseAndTenant, onlyUseCaseAndTenant)) {
            activeUseCaseAndTenant = onlyUseCaseAndTenant;
            await setActiveUseCaseAndTenant(activeUseCaseAndTenant);
        }
    }
    return activeUseCaseAndTenant;
}

export async function profileWithPinExists() {
    const profiles = await _p.localStorage.getKey('profiles', '', undefined, false);
    if (!profiles) {
        return false;
    }
    return Object.values(profiles).some(profile => profile.offlinePin != null);
}

export async function setActiveUseCaseAndTenant(activeUseCaseAndTenant) {
    await _p.localStorage.setKey('activeUseCaseAndTenant', activeUseCaseAndTenant, '', false);
    publishChange('activeUseCaseAndTenant', activeUseCaseAndTenant);
}

export async function setHaven(haven) {
    await _p.localStorage.setKey('haven', haven, '', false);
}
export async function clearHaven() {
    await _p.localStorage.deleteKey('haven', '', false);
}
export async function getHaven() {
    return await _p.localStorage.getKey('haven', '', undefined, false);
}

export async function disableSync() {
    await _p.localStorage.setKey('syncEnabled', false, undefined, false);
}
export async function enableSync() {
    await _p.localStorage.setKey('syncEnabled', true, undefined, false);
}

export async function clearActiveUseCaseAndTenant() {
    await _p.localStorage.deleteKey('activeUseCaseAndTenant', '', false);
    await clearHaven();
    publishChange('activeUseCaseAndTenant', null);
}

export async function getProfileWithUserNameAndPin(userName, pin) {
    const profiles = await _p.localStorage.getKey('profiles', '', undefined, false);
    if (!profiles) {
        return;
    }
    for (const profile of Object.values(profiles)) {
        if (profile.userName === userName) {
            const salt = profile.salt;
            const result = await encryption.bcrypt.compare(pin.toString() + salt, profile.offlinePin);
            if (result) {
                return profile;
            }
        }
    }
}
