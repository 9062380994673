import { Fragment, createElement as rc, memo } from 'react';
import PropTypes from 'prop-types';
import RouteContainer from '../../RouteContainer';
import LayoutTitle from './LayoutTitle';
import doChildrenRequireSidebar from './doChildrenRequireSidebar.js';
// const themedLogos = require('../themedLogos');
// const { whiteLogo, colorLogo } = themedLogos;
import HNode from '../../../HNode';
import NotificationIcon from '../../notification/NotificationIcon';
import SyncDialog from '../../dialog/SyncDialog';
import { metadata } from 'lib_ui-services';
import { fromTheme, View, styled } from 'lib_ui-primitives';
import NavbarContextProvider from '../NavbarContextProvider';
import ActiveRecord from '../../contextProviders/ActiveRecord';
import MainContainer from './MainContainer';
import Header from './Header';
Header.displayName = 'LeftSideNavAppLayoutHeader';
const RightHeader = styled(View).attrs({ name: 'right-header' })`
    flex-basis: auto;
    flex-direction: row;
    align-items: center;
    margin-right: ${fromTheme('outerMenus', 'margin')};
`;


function LeftSideNavAppLayout(props) {
    const topMenuHNodes = metadata.getTopMenu();
    const { id, hNode, currentRoute, _excludeSideBar = false } = props;
    const excludeSideBar = _excludeSideBar || !doChildrenRequireSidebar(hNode.children?.[0]);


    //HACK, MDC3.0 needs to be updated to properly manage a splash page.
    //Right now, it relies on a top-level menu entry/page with the title "SPLASH", "somewhere" in the menu list
    //Ideally it should get it's own place slightly apart in the menu, or better yet, on a page of it's own.
    const splashScreenIndex = hNode.children[0]?.children?.findIndex(child => child.title === 'SPLASH');
    if (splashScreenIndex > -1) {
        const [splashPageNavHeading] = hNode.children[0].children.splice(splashScreenIndex, 1);
        const splashNode = {
            hNodeType: 'SplashContextAppLayout',
            hNodeTypeGroup: 'appLayout',
            namespace: splashPageNavHeading.children?.[0]?.defaultNamespace || 'metadata',
            relation: splashPageNavHeading.children?.[0]?.defaultRelation || 'useCaseDetail',
            splashPage: splashPageNavHeading,
            children: [hNode]
        };
        return rc(HNode, { ...props, hNode: splashNode, children: undefined });
    }

    // prettier-ignore
    return rc(NavbarContextProvider, null,
        //topBar
        rc(Header, null,
            rc(RightHeader, null,
                rc(LayoutTitle),
                rc(NotificationIcon, { id:`notif${id}`}),
                rc(ActiveRecord, {namespace: 'security',relation: 'profile'},
                    topMenuHNodes.map(topMenuHNode =>
                        rc(HNode, { hNode: topMenuHNode, key:topMenuHNode.id, currentRoute })
                    )
                ),
                rc(SyncDialog, { id:`sync${id}`})
            )
        ),
        // Sidebar (nav_SideNav) with NavHeaders
        !excludeSideBar && rc(Fragment, null, props.children),

        //main content
        rc(MainContainer, {excludeSideBar},
            rc(RouteContainer, { hNode, currentRoute })
        )
    );
}

LeftSideNavAppLayout.propTypes = {
    children: PropTypes.array,
    hNode: PropTypes.object,
    skipSideBar: PropTypes.bool
};
export default memo(LeftSideNavAppLayout);
