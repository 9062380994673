import { useTheme } from 'styled-components';
import { createElement as rc, useContext, useEffect, useCallback, useState } from 'react';
import logging from '@sstdev/lib_logging';
import Logo from '../Logo';
import {
    Drawer,
    View,
    GestureContext,
    Text,
    fromTheme,
    webOnlyStyles,
    styled,
    nativeOnlyStyles,
    ScrollView
} from 'lib_ui-primitives';
import { NavbarContext } from '../appLayout/NavbarContextProvider';
import useSpaceConstrained from '../../hooks/useSpaceConstrained';
import propTypes from 'prop-types';

const ANIMATION_TIME = 350;

const SideNavHeader = styled(View).attrs({ name: 'side-nav-header' })`
    min-height: ${({ theme }) => theme.outerMenus.headerHeight - theme.outerMenus.borderWidth + 'px'};
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-basis: auto;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
`;

let SideNavFooter = styled(View).attrs({ name: 'sidenav-footer' })`
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    border-right-color: ${fromTheme('outerMenus', 'border')};
    justify-content: center;
    padding-bottom: 8px;
    padding-top: 8px;
    border-right-width: ${({ theme }) => (theme.mobile ? 0 : theme.outerMenus.borderWidth + 'px')};
`;
SideNavFooter = webOnlyStyles(SideNavFooter)`
    border-right-style: solid;
`;

const SideNavContent = styled(View).attrs({ name: 'sidenav-content' })`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: ${fromTheme('outerMenus', 'backgroundColor')};
`;

let SideNavChildren = styled(ScrollView).attrs({ name: 'side-nav-children' })`
    flex-grow: 1;
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: ${({ theme }) => theme.viewMarginMore + 'px'};
    padding-left: ${({ theme }) => theme.viewMarginMore + 'px'};
    border-right-color: ${fromTheme('outerMenus', 'border')};
    border-right-width: ${({ open, theme }) => (open && theme.mobile ? 0 : theme.outerMenus.borderWidth + 'px')};
`;
SideNavChildren = webOnlyStyles(SideNavChildren)`
    border-right-style: solid;
`;
// I tried to figure out why this was necessary to push the
// scrolling portion of the sidebar into the view, but I wasn't
// able to.
// Interestingly - even if this differs by device, it is unlikely to matter because it
// will be really unusual to have enough entries in the native sidebar that it matters
// with devices larger than the MC3X (which I tested with).
SideNavChildren = nativeOnlyStyles(SideNavChildren)`
    margin-bottom: 25px;
`;

let CollapsingDrawer = styled(Drawer).attrs({ name: 'collapsing-drawer' })`
    max-height: ${({ theme: { mobile, height, outerMenus }, open, collapsed }) => {
        if (mobile && collapsed && !open) return '0';
        return open ? height + 'px' : outerMenus.headerHeight + 'px';
    }};
    overflow: hidden;
`;
CollapsingDrawer = webOnlyStyles(CollapsingDrawer)`
    transition: max-height 0.3s linear, transform 0.3s linear;
`;

let DragAffordance = styled(View).attrs({ name: 'drag-affordance' })`
    position: absolute;
    top: 70px;
    left: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 2px;
    background-color: ${fromTheme('backgroundColorDarker')};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
`;

DragAffordance = nativeOnlyStyles(DragAffordance)`
    padding-left: 1px;
`;

const FOUR_VERTICAL_DOTS = '\u205E\u205E';
const Handle = styled(Text)`
    font-size: 24px;
`;

/**
 * Navigation container/wrapper providing space for menu (in a collapsible drawer)
 * Does NOT provide navigation by itself. That is implemented in navHeading:NavHeading
 */
function SideNav(props) {
    const { onSwipeLeft, onSwipeRight, onSwipeUp, onSwipeDown } = useContext(GestureContext);
    const { open, setOpen, locked, setLocked, ready } = useContext(NavbarContext);
    const theme = useTheme();
    const [collapsed, setCollapsed] = useState(false);
    const [constrained] = useSpaceConstrained();

    // If a user switches between desktop and mobile view and the sidebar is locked,
    // unlock it here.
    useEffect(() => {
        if (theme.mobile && locked) {
            setLocked(false);
            if (open) {
                setOpen(false);
            }
        }
    }, [open, locked, setLocked, setOpen, theme.mobile]);

    //when the user unlocks the sidebar, close it immediately, don't wait for a mouse-off
    useEffect(() => {
        if (!locked) {
            setOpen?.(false);
        }
    }, [locked, setOpen]);

    useEffect(() => {
        // prettier-ignore
        let unsubscribes = [
            onSwipeUp(() => setCollapsed(true)),
            onSwipeDown(() => setCollapsed(false))
        ];
        return () => unsubscribes.forEach(u => u());
    }, [onSwipeUp, onSwipeDown]);

    useEffect(() => {
        // prettier-ignore
        let unsubscribes = [
            onSwipeLeft(() => {
                logging.debug('[MENU] Drag Close');
                return setOpen(false);
            }),
            onSwipeRight(() => {
                logging.debug('[MENU] Drag Open');
                return setOpen(true);
            })
        ];
        return () => unsubscribes.forEach(u => u());
    }, [onSwipeLeft, onSwipeRight, setOpen]);

    const onCloseSideNav = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const { children = [] } = props;

    const onMouseLeave = () => {
        if (open && !locked) {
            setOpen(false);
        }
    };
    if (constrained && !open) {
        return rc(DragAffordance, { onClick: () => setOpen(true) }, rc(Handle, null, FOUR_VERTICAL_DOTS));
    }

    if (!ready) {
        return null;
    }
    // prettier-ignore
    return rc(CollapsingDrawer, { collapsed: collapsed || constrained, drawerPercentage: theme.drawerWidthPercentage, open, setOpen, setLocked, locked, close: onCloseSideNav, animationTime: ANIMATION_TIME },
        rc(SideNavContent, { onMouseLeave },
            rc(SideNavHeader, { key: 'side-nav-header' }),
            rc(SideNavChildren, { open, key: 'side-nav-children' }, open && children),
            !theme.native && rc(SideNavFooter, { key: 'side-nav-footer' },
                rc(Logo)
            )
        )
    );
}

SideNav.propTypes = {
    children: propTypes.node
};
export default SideNav;
